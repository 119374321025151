<template>
    <div class="chat-list uf uf-column wi100">
        <div class="uf uf-ac pr lh2 pt-2 pb-2 border-bottom">
            <div class="" style="margin: 0 auto;">消息列表</div>
            <div class="title-close" @click="$router.go(-1)">
                <i class="el-icon-arrow-left"></i>
            </div>
        </div>
        <!--                消息列表-->
        <el-scrollbar class="wi100 uf-f1 sys-zd"  v-loading="dataListLoading" id="messageScroll" ref="msgBox">
            <div class="uf uf-ac cp wi100 border-bottom" style="padding: 10px;" v-for="(item, index) in expertList" :key="index" @click="expertSwitch(item)">
                <el-badge :value="item.unreadCount" :max="99" class="item" :hidden="item.unreadCount <= 0">
                    <el-avatar shape="square" style="width: 45px;height: 45px;"
                               src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
                </el-badge>
                <div class="ml-2 uf-f1 lh1 " style="width: calc(100vw - 80px);">
                    <div class="uf uf-ac uf-pj">
                        <div class="uf-f1 text-hiding">{{item.sysUserEntity.name}}</div>
                        <div class="f12 op-05">{{item.bsRoomMsgEntity.sendTime}}</div>
                    </div>
                    <div class="mt-1 text-hiding" style="max-width: 100%">
                        <span v-if="item.bsRoomMsgEntity.msgType === 'TEXT'">{{item.bsRoomMsgEntity.msgText}}</span>
                        <span v-else>图片</span>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        name: 'chat-list',
        data () {
            return {
                pageIndex: 1,
                pageSize: 10,
                dataListLoading: false,
                expertList: [],
                expertTotalPage: 0
            }
        },
        created () {
            this.getMessageList()
        },
        mounted () {
            let that = this
            document.getElementById('messageScroll').addEventListener('scroll', that.messageScrollList, true)
        },
        methods: {
            //  获取当前消息成员列表
            getMessageList (isPage = false) {
                if (isPage) {
                    this.pageIndex = 1
                }
                this.dataListLoading = true
                this.$http({
                    url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.MESSAGE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.pageIndex,
                        limit: this.pageSize,
                    })
                }).then(({data}) => {
                    this.dataListLoading = false
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        this.expertList = this.expertList.concat(page.list || [])
                        // for (let i = 0; i < 20; i++) {
                        //     this.expertList.push({
                        //         sysUserEntity: {
                        //             name: i
                        //         },
                        //         bsRoomMsgEntity: {}
                        //     })
                        // }
                        this.expertTotalPage = page.totalPage || 0
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            expertSwitch (item) {
                this.$nextTick(() => {
                    this.$router.push({name: 'chat-user', query: {
                            doctorInfo: item.sysUserEntity
                        }})
                })
            },
        //    消息列表触底加载
            messageScrollList () {
                let scrollTop = this.$refs.msgBox.$refs.wrap.scrollTop
                let clientHeight = this.$refs.msgBox.$refs.wrap.clientHeight
                let scrollHeight = this.$refs.msgBox.$refs.wrap.scrollHeight
                // console.log(scrollTop, clientHeight, scrollHeight)
                if (scrollTop + clientHeight === scrollHeight) {
                    if (this.pageIndex < this.expertTotalPage) {
                        this.pageIndex = this.pageIndex + 1
                        this.getMessageList()
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .chat-list {
        width: 100vw;
        height: 100vh;
        background-color: #fff;
    }
    .title-close {
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        font-size: 24px;
    }
    /*    文本溢出隐藏*/
    .text-hiding {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>